import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const faqs = [
  {
    question: "Is the free trial really free?",
    answer:
      "Yes, our 7-day free trial includes full access to all modules with no hidden charges or credit card required."
  },
  {
    question: "What happens after the trial ends?",
    answer:
      "You can choose a plan that fits your business. Your data remains secure, and you can upgrade anytime."
  },
  {
    question: "How secure is my data?",
    answer:
      "RedERP is hosted on Microsoft Azure with encryption in transit and at rest, backed by ISO and GDPR compliance."
  },
  {
    question: "Can I cancel or switch plans?",
    answer:
      "Absolutely. You can cancel anytime or upgrade/downgrade your plan without losing your data."
  },
  {
    question: "Do you offer customer support?",
    answer:
      "Yes, our team is available via chat and email. We also offer onboarding and priority support for paid plans."
  }
];

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggle = (i) => {
    setOpenIndex(openIndex === i ? null : i);
  };

  return (
    <section className="bg-white py-20 px-6 border-t">
      <div className="max-w-4xl mx-auto">
        <motion.h2
          className="text-3xl font-bold text-gray-900 text-center mb-4"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Frequently Asked Questions
        </motion.h2>
        <motion.p
          className="text-center text-gray-600 mb-12"
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          Everything you need to know before getting started with RedERP.
        </motion.p>

        <div className="space-y-4">
          {faqs.map((faq, i) => (
            <div
              key={i}
              className="border border-gray-200 rounded-lg overflow-hidden"
            >
              <button
                onClick={() => toggle(i)}
                className="w-full flex justify-between items-center px-4 py-4 text-left text-gray-800 font-medium hover:bg-gray-50"
              >
                {faq.question}
                {openIndex === i ? (
                  <FiChevronUp className="text-primary" />
                ) : (
                  <FiChevronDown className="text-gray-500" />
                )}
              </button>

              <AnimatePresence>
                {openIndex === i && (
                  <motion.div
                    className="px-4 pb-4 text-sm text-gray-600"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {faq.answer}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;

import Layout from "../components/Layout";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <Layout>
      <Helmet>
        <title>About | RedERP</title>
        <meta name="description" content="Learn more about the RedERP team and mission." />
      </Helmet>
      <section className="py-32 px-6 text-center">
        <h1 className="text-4xl font-bold text-primary">About RedERP</h1>
        <p className="mt-4 text-lg text-gray-600">We’re building modern ERP solutions for real-world business challenges.</p>
      </section>
    </Layout>
  );
};

export default About;

import Layout from "../components/Layout";
import { Helmet } from "react-helmet-async";
import {
  FiTruck,
  FiUsers,
  FiSettings,
  FiBriefcase,
  FiHome,
  FiClipboard,
  FiShoppingBag,
} from "react-icons/fi";

const useCases = [
  {
    icon: <FiSettings />,
    title: "Manufacturing",
    desc: "Automate production plans, monitor costs, manage resources in real time.",
  },
  {
    icon: <FiTruck />,
    title: "Logistics",
    desc: "Streamline delivery, manage fleets, and optimize routes efficiently.",
  },
  {
    icon: <FiShoppingBag />,
    title: "Retail",
    desc: "POS, inventory, pricing rules and customer management—all integrated.",
  },
  {
    icon: <FiUsers />,
    title: "Human Resources",
    desc: "Manage payrolls, leaves, contracts, loans and recruitment pipelines.",
  },
  {
    icon: <FiClipboard />,
    title: "Project Management",
    desc: "Time tracking, task scheduling, and budget tracking for teams.",
  },
  {
    icon: <FiHome />,
    title: "Construction",
    desc: "Track progress, subcontractors, site tasks and materials in one dashboard.",
  },
  {
    icon: <FiBriefcase />,
    title: "Service Agencies",
    desc: "Handle CRM, quotes, client invoicing, timesheets, and reporting in one app.",
  },
];

const UseCases = () => {
  return (
    <Layout>
      <Helmet>
        <title>Use Cases | RedERP</title>
        <meta
          name="description"
          content="Explore real-world use cases of RedERP across industries."
        />
      </Helmet>

      <section className="py-28 bg-gray-50 text-center">
        <div className="max-w-3xl mx-auto mb-12">
          <h1 className="text-4xl font-bold text-primary mb-4">Real Use Cases</h1>
          <p className="text-lg text-gray-600">
            Discover how RedERP powers businesses of every size and sector.
          </p>
        </div>

        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 text-left">
          {useCases.map((useCase, i) => (
            <div
              key={i}
              className="bg-white rounded-xl p-6 shadow hover:shadow-lg transition-all duration-300 border border-gray-100"
            >
              <div className="text-primary text-2xl mb-4">{useCase.icon}</div>
              <h3 className="text-xl font-bold mb-2">{useCase.title}</h3>
              <p className="text-sm text-gray-600">{useCase.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Final CTA */}
      <section className="bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white w-full">
        <div className="max-w-7xl mx-auto px-6 flex flex-col md:flex-row items-center justify-between gap-10 relative">
          <div className="flex-1 text-left">
            <h3 className="text-3xl font-bold mb-4">Ready to simplify your business operations?</h3>
            <p className="mb-6 text-sm sm:text-base">
              Start today and unlock the power of RedERP for only $5/month.
            </p>
            <a
              href="https://app.rederp.pro/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                if (typeof window !== "undefined" && window.gtag) {
                  window.gtag("event", "click", {
                    event_category: "CTA",
                    event_label: "Sign Up - Use Cases Page",
                  });
                }
              }}
              className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
            >
              Get Started Now
            </a>
          </div>

          <div className="flex-1 hidden md:flex justify-end">
            <img
              src="/assets/images/cta2.png"
              alt="CTA Illustration"
              className="w-[320px] lg:w-[380px] xl:w-[420px] object-contain"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default UseCases;

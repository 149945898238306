import Layout from "../../components/Layout";
import { Helmet } from "react-helmet-async";
import {
  FiFileText,
  FiCreditCard,
  FiUsers,
  FiDollarSign,
  FiTrendingUp,
  FiBarChart2,
  FiSettings,
  FiDownload,
  FiRefreshCw,
  FiGlobe,
  FiCheckCircle,
} from "react-icons/fi";

const ModuleSales = () => {
  const trackCTA = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "CTA",
        event_label: "Sign Up - Sales Page",
      });
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Sales and Invoicing | RedERP</title>
        <meta
          name="description"
          content="RedERP Sales and Invoicing module: Automate your quotes, invoices, payments, and customer follow-ups with ease."
        />
      </Helmet>

      {/* Hero Block with image */}
      <section className="bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between gap-10 relative">
          {/* Text Section */}
          <div className="flex-1 text-left mt-16">
            <h1 className="text-4xl font-bold mb-4">Sales and Invoicing</h1>
            <p className="mb-6 text-sm sm:text-base">
              Streamline your quoting, invoicing, payments, and customer communication. Manage your entire sales workflow with RedERP.
            </p>
            <a
              href="https://app.rederp.pro/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackCTA}
              className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
            >
              Get Started Now
            </a>
          </div>

          {/* Decorative Image */}
          <div className="flex-1 hidden md:flex justify-end mt-12 mb-0">
            <img
              src="/assets/images/sales1.png"
              alt="Sales Illustration"
              className="w-[420px] lg:w-[420px] xl:w-[420px] h-[310px] object-contain"
            />
          </div>
        </div>
      </section>

      {/* Sales Features */}
      <section className="py-20 px-6 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-left">
          {[
            {
              icon: <FiFileText size={24} />,
              title: "Create Quotes & Proposals",
              desc: "Easily send professional quotes and convert them to invoices in one click.",
            },
            {
              icon: <FiCreditCard size={24} />,
              title: "Online Payments",
              desc: "Accept card and digital payments directly on invoices to get paid faster.",
            },
            {
              icon: <FiDollarSign size={24} />,
              title: "Multi-Currency Invoicing",
              desc: "Issue invoices in local or foreign currencies with automatic conversion.",
            },
            {
              icon: <FiDownload size={24} />,
              title: "PDF & E-Invoice Export",
              desc: "Download compliant invoices with QR code or send directly via email.",
            },
            {
              icon: <FiRefreshCw size={24} />,
              title: "Recurring Billing",
              desc: "Automate subscription-based invoicing with defined cycles and terms.",
            },
            {
              icon: <FiBarChart2 size={24} />,
              title: "Sales Reports",
              desc: "Track revenue, overdue invoices, aging reports, and payment statuses.",
            },
            {
              icon: <FiGlobe size={24} />,
              title: "Tax & Compliance",
              desc: "Comply with local tax rules including VAT, GST, and withholding tax.",
            },
            {
              icon: <FiUsers size={24} />,
              title: "Customer Management",
              desc: "Track contact history, payment terms, discounts, and communication logs.",
            },
            {
              icon: <FiSettings size={24} />,
              title: "Customizable Templates",
              desc: "Use your logo and branding on quotes, pro forma, and invoices.",
            },
          ].map((item, i) => (
            <div
              key={i}
              className="p-6 bg-gray-50 border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all"
            >
              <div className="flex items-center gap-3 text-primary mb-3">
                {item.icon}
                <h3 className="font-bold">{item.title}</h3>
              </div>
              <p className="text-sm text-gray-600">{item.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20 bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white text-center">
        <div className="max-w-xl mx-auto px-6">
          <h3 className="text-3xl font-bold mb-4">Simplify Your Sales Cycle with RedERP</h3>
          <p className="mb-6">Start your 7-day free trial. No credit card required.</p>
          <a
            href="https://app.rederp.pro/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
          >
            Try RedERP Sales Module
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default ModuleSales;

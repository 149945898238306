import Layout from "../../components/Layout";
import { Helmet } from "react-helmet-async";
import {
  FiBox,
  FiTruck,
  FiDatabase,
  FiAlertTriangle,
  FiClipboard,
  FiCheckCircle,
  FiTrendingUp,
  FiLayers,
  FiTag,
  FiSettings,
} from "react-icons/fi";

const ModuleInventoryStock = () => {
  const trackCTA = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "CTA",
        event_label: "Sign Up - Inventory Page",
      });
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Inventory & Stock | RedERP</title>
        <meta
          name="description"
          content="Track inventory, manage warehouses, optimize stock levels, and streamline procurement with RedERP’s Inventory & Stock module."
        />
      </Helmet>

      {/* Hero Block */}
      <section className="bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between gap-10 relative">
          {/* Text */}
          <div className="flex-1 text-left mt-16">
            <h1 className="text-4xl font-bold mb-4">Inventory & Stock</h1>
            <p className="mb-6 text-sm sm:text-base">
              Get full visibility into your stock movement, optimize warehouse operations,
              and never run out of inventory again — all in real-time.
            </p>
            <a
              href="https://app.rederp.pro/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackCTA}
              className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
            >
              Get Started Now
            </a>
          </div>

          {/* Image */}
          <div className="flex-1 hidden md:flex justify-end mt-12 mb-0">
            <img
              src="/assets/images/inventory1.png"
              alt="Inventory Illustration"
              className="w-[420px] lg:w-[420px] xl:w-[420px] h-[310px] object-contain"
            />
          </div>
        </div>
      </section>

      {/* Feature List */}
      <section className="py-20 px-6 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-left">
          {[
            {
              icon: <FiBox size={24} />,
              title: "Inventory Tracking",
              desc: "Monitor item quantities, product movement, and batch/serial numbers in real-time.",
            },
            {
              icon: <FiTruck size={24} />,
              title: "Multi-Warehouse Management",
              desc: "Manage stock across multiple storage locations with transfers and real-time updates.",
            },
            {
              icon: <FiAlertTriangle size={24} />,
              title: "Low Stock Alerts",
              desc: "Set minimum thresholds and get alerts to replenish before running out.",
            },
            {
              icon: <FiClipboard size={24} />,
              title: "Stock Requisition",
              desc: "Allow team members to request stock with approval workflows and traceability.",
            },
            {
              icon: <FiDatabase size={24} />,
              title: "Inventory Valuation",
              desc: "Support FIFO, LIFO, and weighted average valuation methods for accounting.",
            },
            {
              icon: <FiTrendingUp size={24} />,
              title: "Inventory Reports & KPIs",
              desc: "Track stock aging, turnover rates, dead stock, and inventory performance.",
            },
            {
              icon: <FiLayers size={24} />,
              title: "Item Variants & Batches",
              desc: "Create variants for color, size, and attributes. Track expiry dates and batch IDs.",
            },
            {
              icon: <FiTag size={24} />,
              title: "Barcoding & Labels",
              desc: "Generate and print barcode labels for easy scanning and stock intake.",
            },
            {
              icon: <FiSettings size={24} />,
              title: "Purchase Integration",
              desc: "Seamlessly create purchase orders from low-stock items or planned demand.",
            },
          ].map((item, i) => (
            <div
              key={i}
              className="p-6 bg-gray-50 border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all"
            >
              <div className="flex items-center gap-3 text-primary mb-3">
                {item.icon}
                <h3 className="font-bold">{item.title}</h3>
              </div>
              <p className="text-sm text-gray-600">{item.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20 bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white text-center">
        <div className="max-w-xl mx-auto px-6">
          <h3 className="text-3xl font-bold mb-4">Take Control of Your Inventory</h3>
          <p className="mb-6">Start your 7-day free trial. No credit card required.</p>
          <a
            href="https://app.rederp.pro/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
          >
            Try Inventory Module
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default ModuleInventoryStock;

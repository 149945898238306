import { motion } from "framer-motion";
import { FiBriefcase, FiTrendingUp, FiUsers } from "react-icons/fi";

const personas = [
  {
    icon: <FiBriefcase size={28} />,
    title: "Small & Medium Businesses",
    desc: "Manage operations, inventory, sales, and finance—all in one place with minimal setup."
  },
  {
    icon: <FiTrendingUp size={28} />,
    title: "Enterprises",
    desc: "Streamline complex processes with advanced reporting, custom workflows, and deep integrations."
  },
  {
    icon: <FiUsers size={28} />,
    title: "NGOs & Public Sector",
    desc: "Track budgets, resources, and mission-critical outcomes with full transparency."
  }
];

const WhoIsRedERPFor = () => {
  return (
    <section className="bg-gray-50 py-20 px-6 border-t">
      <div className="max-w-6xl mx-auto text-center">
        <motion.h2
          className="text-3xl font-bold text-gray-900 mb-4"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Who is RedERP for?
        </motion.h2>
        <motion.p
          className="text-gray-600 max-w-xl mx-auto mb-12"
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          Whether you're a growing startup or a government agency, RedERP adapts to your structure and scale.
        </motion.p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          {personas.map((p, i) => (
            <motion.div
              key={i}
              className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md text-left transition"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: i * 0.2 }}
              viewport={{ once: true }}
            >
              <div className="text-primary mb-4">{p.icon}</div>
              <h3 className="text-lg font-semibold text-gray-800 mb-2">{p.title}</h3>
              <p className="text-sm text-gray-600">{p.desc}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhoIsRedERPFor;

import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Hero from "../components/Hero";
import FeatureSection from "../components/FeatureSection";
import TestimonialSection from "../components/TestimonialSection";
import ModuleSection from "../components/ModuleSection";
import BlogTeaserSection from "../components/BlogTeaserSection";
import GlobalReachSection from "../components/GlobalReachSection";
import WhoIsRedERPFor from "../components/WhoIsRedERPFor";
import TrustedBySection from "../components/TrustedBySection";
import SecuritySection from "../components/SecuritySection";
import FAQSection from "../components/FAQSection";
import UseCaseHighlight from "../components/UseCaseHighlight";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Helmet>
        <title>{t("homeTitle")} | RedERP</title>
        <meta name="description" content={t("homeSubtitle")} />
      </Helmet>
      <Hero />
      <FeatureSection />
      <TestimonialSection />
      <ModuleSection />
      <BlogTeaserSection />
      <GlobalReachSection />
      <WhoIsRedERPFor />
      <TrustedBySection />
      <SecuritySection />
      <FAQSection />
      <UseCaseHighlight />
    </Layout>
  );
};

export default Home;

import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import logosData from "../data/logos.json";

const TrustedBySection = () => {
  const [logos, setLogos] = useState([]);
  const scrollRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setLogos(logosData);
  }, []);

  // Intersection Observer to pause animation when off-screen
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) observer.unobserve(scrollRef.current);
    };
  }, []);

  return (
    <section className="relative bg-white py-20 px-6 border-t overflow-hidden">
      <div className="max-w-6xl mx-auto text-center z-10 relative">
        <motion.h2
          className="text-3xl font-bold text-gray-900 mb-3"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          You're in Good Company
        </motion.h2>

        <motion.p
          className="text-gray-600 max-w-2xl mx-auto mb-12"
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          RedERP is trusted by forward-thinking businesses around the globe — from local shops to enterprise operations.
        </motion.p>

        
        
        {/* Auto-scrolling logos */}
        <div
          className={`overflow-hidden relative group`}
          ref={scrollRef}
        >
          <div
            className={`flex gap-12 w-max px-4 ${
              isVisible ? "animate-scroll" : "pause-animation"
            } group-hover:reverse-scroll`}
          >
            {[...logos, ...logos].map((logo, i) => (
              <motion.div
                key={i}
                className="flex items-center justify-center w-48 h-20 shrink-0"
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.4, delay: i * 0.05 }}
              >
                <img
                  src={logo.src}
                  alt={logo.name}
                  className="max-h-16 object-contain grayscale hover:grayscale-0 transition-all duration-300"
                />
              </motion.div>
            ))}
          </div>
        </div>

        {/* CTA block */}
        <div className="mt-16 bg-primary text-white rounded-xl px-6 py-12 max-w-7xl mx-auto shadow text-center">
          <h3 className="text-xl md:text-2xl font-bold mb-2">Join 50+ forward-thinking teams using RedERP</h3>
          <p className="text-sm md:text-base mb-4">
            Our platform powers efficient workflows for all industries. Start your journey today with no commitments.
          </p>
          <a
            href="https://app.rederp.pro/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
          >
            Get Started for Free
          </a>
        </div>
      </div>

      {/* Animation styles */}
      <style>
        {`
          @keyframes scroll {
            0% { transform: translateX(0); }
            100% { transform: translateX(-50%); }
          }

          @keyframes reverse-scroll {
            0% { transform: translateX(-50%); }
            100% { transform: translateX(0); }
          }

          .animate-scroll {
            animation: scroll 90s linear infinite;
          }

          .reverse-scroll {
            animation: reverse-scroll 90s linear infinite !important;
          }

          .pause-animation {
            animation-play-state: paused !important;
          }
        `}
      </style>
    </section>
  );
};

export default TrustedBySection;

import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiMenu, FiX } from "react-icons/fi"; // Import icons

const Navbar = ({ modules, industries }) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [modulesOpen, setModulesOpen] = useState(false);
  const [industriesOpen, setIndustriesOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(null); // Track which dropdown is open

  const modulesRef = useRef(null);
  const industriesRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modulesRef.current && !modulesRef.current.contains(event.target)) {
        setModulesOpen(false);
      }
      if (industriesRef.current && !industriesRef.current.contains(event.target)) {
        setIndustriesOpen(false);
      }
      setMobileDropdownOpen(null); // Close mobile dropdowns on outside click
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-white shadow z-10">
      <div className="flex items-center justify-between py-4 max-w-7xl mx-auto px-4 sm:px-0">
        <Link to="/" onClick={() => setMenuOpen(false)}>
          <img src="/assets/images/logo.png" alt="RedERP Logo" className="h-8 w-auto" />
        </Link>

        <nav className="hidden md:flex space-x-6 items-center">
          <Link to="/" className="hover:text-primary">{t("home")}</Link>

          <div className="relative" ref={modulesRef}>
  <button onClick={() => setModulesOpen((prev) => !prev)} className="hover:text-primary">
    {t("modules")} ▾
  </button>

  {modulesOpen && (
    <div className="absolute bg-white shadow-lg rounded-lg mt-3 text-base p-4 space-y-2 z-50 w-52">
      <Link to="/modules/sales" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Sales & Invoicing
      </Link>
      <Link to="/modules/pos" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Point of Sales
      </Link>
      <Link to="/modules/ecommerce" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        eCommerce
      </Link>
      <Link to="/modules/inventory-stock" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Inventory & Stock
      </Link>
      <Link to="/modules/logistics" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Logistics
      </Link>
      <Link to="/modules/finance" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Finance
      </Link>
      <Link to="/modules/crm" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        CRM
      </Link>
      <Link to="/modules/human-resources" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Human Resources
      </Link>
      <Link to="/modules/procurement" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Procurement
      </Link>
      <Link to="/modules/project-management" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Project Management
      </Link>
      <Link to="/modules/manufacturing-production" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Manufacturing & Production
      </Link>
      <Link to="/modules/bi-reporting" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Business Intelligence & Reporting
      </Link>
      <Link to="/modules/assets-management" className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded">
        Asset Management
      </Link>
    </div>
  )}
</div>


          <Link to="/use-cases" className="hover:text-primary">{t("useCases")}</Link>

          <div className="relative" ref={industriesRef}>
            <button onClick={() => setIndustriesOpen((prev) => !prev)} className="hover:text-primary">
              {t("industries")} ▾
            </button>
            {industriesOpen && (
              <div className="absolute bg-white shadow-lg rounded-lg mt-3 text-base p-4 space-y-2 z-50 w-52">
                {industries.map((industry, i) => (
                  <Link
                    key={i}
                    to={`/industries/${industry.toLowerCase().replace(/\s+/g, '-')}`}
                    className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded"
                  >
                    {industry}
                  </Link>
                ))}
              </div>
            )}
          </div>

          <Link to="/demo" className="hover:text-primary">{t("demo")}</Link>
          <Link to="/pricing" className="hover:text-primary">{t("pricing")}</Link>
          <Link to="/learning" className="hover:text-primary">{t("learning")}</Link>
          <Link to="/about" className="hover:text-primary">{t("about")}</Link>
        </nav>

        <button
          className="md:hidden text-primary"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>

      {menuOpen && (
        <div className="md:hidden bg-white px-8 pt-6 pb-6 flex flex-col space-y-3 text-left">
          <Link to="/" className="hover:text-primary" onClick={() => setMenuOpen(false)}>
            {t("home")}
          </Link>

          <div className="relative">
            <button
              onClick={() => setMobileDropdownOpen(mobileDropdownOpen === "modules" ? null : "modules")}
              className="hover:text-primary w-full text-left"
            >
              {t("modules")} ▾
            </button>
            {mobileDropdownOpen === "modules" && (
              <div className="bg-white shadow rounded mt-2 text-sm p-2 space-y-1">
                {modules.map((module, i) => (
                  <Link
                    key={i}
                    to={`/modules/${module.toLowerCase().replace(/\s+/g, '-')}`}
                    className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded"
                    onClick={() => setMenuOpen(false)}
                  >
                    {module}
                  </Link>
                ))}
              </div>
            )}
          </div>

          <Link to="/use-cases" className="hover:text-primary" onClick={() => setMenuOpen(false)}>
            {t("useCases")}
          </Link>

          <div className="relative">
            <button
              onClick={() => setMobileDropdownOpen(mobileDropdownOpen === "industries" ? null : "industries")}
              className="hover:text-primary w-full text-left"
            >
              {t("industries")} ▾
            </button>
            {mobileDropdownOpen === "industries" && (
              <div className="bg-white shadow rounded mt-2 text-sm p-2 space-y-1">
                {industries.map((industry, i) => (
                  <Link
                    key={i}
                    to={`/industries/${industry.toLowerCase().replace(/\s+/g, '-')}`}
                    className="block hover:text-primary hover:bg-gray-100 px-2 py-1 rounded"
                    onClick={() => setMenuOpen(false)}
                  >
                    {industry}
                  </Link>
                ))}
              </div>
            )}
          </div>

          <Link to="/demo" className="hover:text-primary" onClick={() => setMenuOpen(false)}>
            {t("demo")}
          </Link>
          <Link to="/pricing" className="hover:text-primary" onClick={() => setMenuOpen(false)}>
            {t("pricing")}
          </Link>
          <Link to="/learning" className="hover:text-primary" onClick={() => setMenuOpen(false)}>
            {t("learning")}
          </Link>
          <Link to="/about" className="hover:text-primary" onClick={() => setMenuOpen(false)}>
            {t("about")}
          </Link>
        </div>
      )}
    </header>
  );
};

export default Navbar;

import Layout from "../../components/Layout";
import { Helmet } from "react-helmet-async";
import {
  FiShoppingCart,
  FiCreditCard,
  FiPrinter,
  FiUsers,
  FiBox,
  FiBarChart2,
  FiSmartphone,
  FiWifiOff,
  FiFileText,
  FiSettings,
  FiCheckCircle,
} from "react-icons/fi";

const ModulePointOfSale = () => {
  const trackCTA = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "CTA",
        event_label: "Sign Up - POS Page",
      });
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Point of Sale | RedERP</title>
        <meta
          name="description"
          content="RedERP Point of Sale module: Streamline your sales operations with an integrated, user-friendly POS system."
        />
      </Helmet>

      {/* Hero Block with image */}
      <section className="bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between gap-10 relative">
          {/* Text Section */}
          <div className="flex-1 text-left mt-16">
            <h1 className="text-4xl font-bold mb-4">Point of Sale</h1>
            <p className="mb-6 text-sm sm:text-base">
              Ready to simplify your retail or service sales operations? RedERP offers a smart, secure, and scalable POS solution.
            </p>
            <a
              href="https://app.rederp.pro/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackCTA}
              className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
            >
              Get Started Now
            </a>
          </div>

          {/* Decorative Image */}
          <div className="flex-1 hidden md:flex justify-end mt-12 mb-0">
            <img
              src="/assets/images/pos1.png"
              alt="POS Illustration"
              className="w-[520px] lg:w-[520px] xl:w-[520px] object-contain"
            />
          </div>
        </div>
      </section>

      {/* POS Features */}
      <section className="py-20 px-6 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-left">
          {[
            {
              icon: <FiShoppingCart size={24} />,
              title: "Fast and Easy Checkout",
              desc: "Quickly process sales with an intuitive interface designed for speed and ease.",
            },
            {
              icon: <FiCreditCard size={24} />,
              title: "Multiple Payment Methods",
              desc: "Accept cash, credit, debit, and digital payments with full flexibility.",
            },
            {
              icon: <FiPrinter size={24} />,
              title: "Receipt Printing",
              desc: "Print branded receipts with full tax and payment details or send them via email.",
            },
            {
              icon: <FiBox size={24} />,
              title: "Inventory Integration",
              desc: "Connect POS with inventory for accurate stock tracking and replenishment.",
            },
            {
              icon: <FiUsers size={24} />,
              title: "Staff Management",
              desc: "Assign user roles, monitor shifts, and access staff-based reports.",
            },
            {
              icon: <FiBarChart2 size={24} />,
              title: "Sales & KPI Reporting",
              desc: "Access performance dashboards with real-time analytics and filters.",
            },
            {
              icon: <FiSmartphone size={24} />,
              title: "Mobile & Tablet Compatible",
              desc: "Use your POS system from tablets and smartphones – no hardware lock-in.",
            },
            {
              icon: <FiWifiOff size={24} />,
              title: "Offline Mode",
              desc: "Keep selling even without internet. Data syncs automatically when you're online.",
            },
            {
              icon: <FiFileText size={24} />,
              title: "E-Invoice Ready",
              desc: "Generate digital invoices with QR code and compliance standards.",
            },
            {
              icon: <FiSettings size={24} />,
              title: "Fully Configurable",
              desc: "Customize product catalog, payment options, receipts, and taxes.",
            },
          ].map((item, i) => (
            <div
              key={i}
              className="p-6 bg-gray-50 border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all"
            >
              <div className="flex items-center gap-3 text-primary mb-3">
                {item.icon}
                <h3 className="font-bold">{item.title}</h3>
              </div>
              <p className="text-sm text-gray-600">{item.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20 bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white text-center">
        <div className="max-w-xl mx-auto px-6">
          <h3 className="text-3xl font-bold mb-4">Unlock Efficiency with RedERP POS</h3>
          <p className="mb-6">Start your 7-day free trial. No credit card needed.</p>
          <a
            href="https://app.rederp.pro/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
          >
            Get Started for $5/month
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default ModulePointOfSale;

import Layout from "../components/Layout";
import { Helmet } from "react-helmet-async";
import {
  FiShoppingBag,
  FiUsers,
  FiTruck,
  FiBriefcase,
  FiClipboard,
  FiBarChart2,
  FiPackage,
  FiSettings,
  FiChevronsRight,
  FiCheckCircle,
  FiInfo
} from "react-icons/fi";
import { useState } from "react";
import TestimonialSection from "../components/TestimonialSection";
import { Tooltip } from "react-tooltip";

const faqs = [
  {
    question: "Is there a free trial?",
    answer: "Yes, we offer a 7-day free trial with full access to all modules."
  },
  {
    question: "Can I cancel anytime?",
    answer: "Absolutely. RedERP is contract-free. Cancel anytime from your dashboard."
  },
  {
    question: "Do you offer onboarding support?",
    answer: "Yes, we offer onboarding guidance and chat/email support."
  },
  {
    question: "Where is my data hosted?",
    answer: "Your data is encrypted and securely hosted on Microsoft Azure."
  }
];

const Pricing = () => {
  const [billing, setBilling] = useState("monthly");
  const [openFaq, setOpenFaq] = useState(null);

  const price = billing === "monthly" ? "$5" : "$50";
  const billingText = billing === "monthly" ? "Per month" : "Billed annually";

  const trackCTA = () => {
    if (window.gtag) {
      window.gtag("event", "pricing_cta_click", {
        method: "pricing_page_cta"
      });
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Pricing | RedERP</title>
        <meta name="description" content="Affordable ERP pricing made simple. Just $5/month for unlimited users and full access to all RedERP modules." />
      </Helmet>

      <section className="text-center bg-gray-50">
        {/* Header CTA */}
        <div className="mt-16 bg-primary text-white rounded-xl px-6 max-w-7xl mx-auto shadow relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-black/20 to-transparent z-0" />
          <div className="flex flex-col md:flex-row items-center justify-between gap-8 relative z-10">
            <div className="text-center md:text-left flex-1 py-12">
              <h3 className="text-2xl font-bold mb-2">Customized for more than 50 industries, for $5/month for all users.</h3>
              <p className="text-sm mb-4">Further customization available per request. Discover how RedERP can help your business grow.</p>
              <div className="mt-6 flex flex-wrap justify-center md:justify-start gap-4 text-white text-sm">
                {[["Logistics", FiTruck], ["Manufacturing", FiSettings], ["Retail", FiShoppingBag], ["Services", FiUsers], ["And more...", FiChevronsRight]].map(([text, Icon], i) => (
                  <div key={i} className="flex items-center space-x-2">
                    <Icon className="text-white" />
                    <span>{text}</span>
                  </div>
                ))}
              </div>
              <div className="py-4 mt-4">
                <a
                  href="https://app.rederp.pro/sign-up"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={trackCTA}
                  className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
                >
                  Get Started for Free
                </a>
              </div>
            </div>
            <div className="hidden md:block flex-1 h-full absolute right-0 top-0">
              <img src="/assets/images/ctaindustry.png" alt="Industries" className="absolute bottom-0 right-0 max-w-none w-[300px] object-contain animate-float" />
            </div>
          </div>
        </div>

        {/* Pricing Switcher */}
        <div className="max-w-3xl mx-auto mt-16">
          <h1 className="text-4xl font-extrabold text-primary mb-4">Simple, Transparent Pricing</h1>
          <p className="text-lg text-gray-600">One plan. All features. Unlimited users. Only <strong>$5/month</strong>.</p>
          <div className="flex justify-center mt-6 space-x-4 text-sm">
            {["monthly", "annual"].map((mode) => (
              <button
                key={mode}
                onClick={() => setBilling(mode)}
                className={`px-4 py-2 rounded-full border ${billing === mode ? "bg-primary text-white" : "border-gray-300"}`}
              >
                {mode === "monthly" ? "Monthly" : "Annual"}
              </button>
            ))}
          </div>
        </div>

        {/* Pricing Box */}
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 mt-12 items-center">
          <div className="bg-white border-2 border-primary rounded-2xl shadow-lg p-8 text-left relative">
            <span className="absolute top-0 left-0 bg-primary text-white text-xs font-semibold px-3 py-1 rounded-br-xl">Best Value</span>
            <h2 className="text-2xl font-extrabold text-gray-900 mb-2">RedERP All-In-One Plan</h2>
            <p className="text-4xl font-black text-primary mb-1">{price}</p>
            <p className="text-sm text-gray-500 mb-6">{billingText} – no hidden fees</p>
            <div className="border-t pt-4 mt-4">
              <ul className="text-sm text-gray-700 space-y-3">
                {[
                  "Unlimited users",
                  "All 12+ modules included",
                  "Access to future features",
                  "Real-time dashboards & reports",
                  "Cloud-hosted on Microsoft Azure",
                  "Free onboarding support",
                  "Cancel anytime, no contracts"
                ].map((feature, i) => (
                  <li key={i} className="flex items-center gap-2">
                    <FiCheckCircle className="text-green-600" /> {feature}
                  </li>
                ))}
              </ul>
            </div>
            <a
              href="https://app.rederp.pro/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackCTA}
              className="mt-8 inline-block bg-primary text-white px-6 py-3 rounded-full font-semibold hover:bg-primary/90 transition"
            >
              Get Started for {price}
            </a>
          </div>
          <div className="bg-white rounded-2xl shadow-inner p-6 md:p-10 border border-gray-100">
            <img src="/assets/images/benefit.png" alt="Benefits" className="w-full max-w-md mx-auto object-contain" />
          </div>
        </div>

        {/* Comparison Table */}
        <section className="bg-white py-20 px-6">
          <div className="max-w-6xl mx-auto text-center">
            <h3 className="text-3xl font-bold text-gray-900 mb-6">How RedERP Stacks Up</h3>
            <div className="overflow-x-auto rounded-xl shadow ring-1 ring-gray-200">
              <table className="min-w-full bg-white text-sm text-left">
                <thead className="bg-gray-50 border-b">
                  <tr>
                    <th className="px-6 py-4 font-semibold text-gray-700">Feature</th>
                    <th className="px-6 py-4 font-semibold text-primary">RedERP</th>
                    <th className="px-6 py-4 font-semibold text-gray-500">Traditional ERP</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {[
                    ["Monthly Cost", "$5", "$500+"],
                    ["Setup Fees", "❌", "✅"],
                    ["Users Included", "Unlimited", "Per seat license"],
                    ["Setup Time", "Instant (No IT)", "Weeks/Months"],
                    ["Cloud Hosted", "✅ (Azure)", "❌ (Self-hosted)"],
                    ["Support", "✅ Free onboarding", "Extra fee"]
                  ].map(([feature, red, legacy], i) => (
                    <tr key={i} className={i % 2 === 1 ? "bg-gray-50" : ""}>
                      <td className="px-6 py-4 flex items-center gap-1">
                        {feature}
                        {feature === "Setup Time" && <FiInfo data-tooltip-id="tooltip-setup" className="text-gray-400 cursor-pointer" />}
                      </td>
                      <td className="px-6 py-4 font-medium text-green-600">{red}</td>
                      <td className="px-6 py-4 text-gray-500">{legacy}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Tooltip id="tooltip-setup" content="RedERP runs in the cloud, so you can get started immediately." place="top" />
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="bg-gray-100 py-16 px-6">
          <TestimonialSection />
        </section>

        {/* FAQ */}
        <section className="py-20 bg-white px-6 max-w-4xl mx-auto">
          <h3 className="text-2xl font-bold text-gray-900 text-center mb-10">Frequently Asked Questions</h3>
          <div className="space-y-6">
            {faqs.map((faq, i) => (
              <div key={i} className="border rounded-xl p-4 cursor-pointer" onClick={() => setOpenFaq(openFaq === i ? null : i)}>
                <div className="flex justify-between items-center">
                  <h4 className="font-semibold text-gray-800">{faq.question}</h4>
                  <span className="text-primary text-lg">{openFaq === i ? "−" : "+"}</span>
                </div>
                {openFaq === i && <p className="mt-3 text-sm text-gray-600">{faq.answer}</p>}
              </div>
            ))}
          </div>
        </section>

       {/* Final CTA */}
    <section className="bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white">
    <div className="max-w-7xl mx-auto px-6 flex flex-col md:flex-row items-center justify-between gap-10 relative">
        
        {/* Text Section */}
        <div className="flex-1 text-left mt-16">
        <h3 className="text-3xl font-bold mb-4">Ready to simplify your business operations?</h3>
        <p className="mb-6 text-sm sm:text-base">
            Start today and unlock the power of RedERP for only $5/month.
        </p>
        <a
            href="https://app.rederp.pro/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            onClick={trackCTA}
            className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
        >
            Get Started Now
        </a>
        </div>

        {/* Decorative Image */}
        <div className="flex-1 hidden md:flex justify-end">
        <img
            src="/assets/images/cta2.png"
            alt="CTA Illustration"
            className="w-[320px] lg:w-[380px] xl:w-[420px] object-contain"
        />
        </div>
    </div>
    </section>


      </section>
    </Layout>
  );
};

export default Pricing;

import { useTranslation } from "react-i18next";
import {
  FiBarChart2,
  FiGrid,
  FiUsers,
  FiPackage,
  FiServer,
  FiShoppingCart,
  FiSettings,
  FiGlobe
} from "react-icons/fi";
import { motion } from "framer-motion";

const features = [
  {
    icon: <FiGrid size={28} className="text-primary" />,
    titleKey: "featureModules",
    descKey: "featureModulesDesc",
  },
  {
    icon: <FiUsers size={28} className="text-primary" />,
    titleKey: "featureCRM",
    descKey: "featureCRMDesc",
  },
  {
    icon: <FiBarChart2 size={28} className="text-primary" />,
    titleKey: "featureAnalytics",
    descKey: "featureAnalyticsDesc",
  },
  {
    icon: <FiPackage size={28} className="text-primary" />,
    titleKey: "featureInventory",
    descKey: "featureInventoryDesc",
  },
  {
    icon: <FiServer size={28} className="text-primary" />,
    titleKey: "featureCloud",
    descKey: "featureCloudDesc",
  },
  {
    icon: <FiShoppingCart size={28} className="text-primary" />,
    titleKey: "featureEcommerce",
    descKey: "featureEcommerceDesc",
  },
  {
    icon: <FiSettings size={28} className="text-primary" />,
    titleKey: "featureCustomization",
    descKey: "featureCustomizationDesc",
  },
  {
    icon: <FiGlobe size={28} className="text-primary" />,
    titleKey: "featureGlobal",
    descKey: "featureGlobalDesc",
  },
];

const FeatureSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-white py-20 px-6">
      <div className="max-w-7xl mx-auto text-center">
        <motion.h2
          className="text-3xl font-bold mb-6 text-gray-900"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          {t("featuresTitle")}
        </motion.h2>
        <motion.p
          className="text-lg text-gray-600 mb-12"
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
        >
          {t("featuresSubtitle")}
        </motion.p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, i) => (
            <motion.div
              key={i}
              className="bg-gray-50 rounded-xl shadow hover:shadow-md transition p-6 text-left"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: i * 0.1 }}
            >
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {t(feature.titleKey)}
              </h3>
              <p className="text-sm text-gray-600">
                {t(feature.descKey)}
              </p>
            </motion.div>
          ))}
        </div>

        {/* CTA Button */}
        <motion.div
          className="mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <a
            href="https://app.rederp.pro/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-primary text-white px-8 py-3 rounded-full font-semibold hover:bg-primary/90"
          >
            {t("startFreeTrial")}
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default FeatureSection;
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  FiShoppingBag,
  FiUsers,
  FiTruck,
  FiBriefcase,
  FiClipboard,
  FiBarChart2,
  FiPackage,
  FiSettings,
  FiChevronsRight
} from "react-icons/fi";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const modules = [
  {
    icon: <FiShoppingBag size={20} />,
    title: "Sales & PoS",
    link: "sales-pos",
    desc: "Invoicing, POS, Offers, Installments",
    industryTags: ["Retail", "eCommerce"],
    example: "Grocery Shops, Boutiques"
  },
  {
    icon: <FiPackage size={20} />,
    title: "Inventory",
    link: "inventory-stock",
    desc: "Stock Tracking, Purchases, Requisitions",
    industryTags: ["eCommerce", "Retail", "Logistics"],
    example: "Warehouses, Supermarkets"
  },
  {
    icon: <FiBriefcase size={20} />,
    title: "Finance",
    link: "finance",
    desc: "Expenses, Tax, Assets, Reports",
    industryTags: ["General"],
    example: "All businesses"
  },
  {
    icon: <FiUsers size={20} />,
    title: "Human Resource",
    link: "human-resources",
    desc: "Payroll, Contracts, Leaves, Loans",
    industryTags: ["General"],
    example: "HR departments"
  },
  {
    icon: <FiClipboard size={20} />,
    title: "Project Management",
    link: "project-management",
    desc: "Work Orders, Booking, Time Tracking",
    industryTags: ["Construction", "IT"],
    example: "Software Teams, Builders"
  },
  {
    icon: <FiTruck size={20} />,
    title: "Logistics",
    link: "logistics",
    desc: "Delivery & Supply Chain Optimization",
    industryTags: ["eCommerce", "Logistics"],
    example: "Couriers, Distributors"
  },
  {
    icon: <FiBarChart2 size={20} />,
    title: "BI & Reporting",
    link: "bi-reporting",
    desc: "Dashboards, KPIs, Advanced Reports",
    industryTags: ["General"],
    example: "Management & Analysts"
  },
  {
    icon: <FiSettings size={20} />,
    title: "Manufacturing",
    link: "manufacturing-production",
    desc: "Production Plans, Resources, Costing",
    industryTags: ["Manufacturing"],
    example: "Factories, Workshops"
  },
  {
    icon: <FiSettings size={20} />,
    title: "eCommerce",
    link: "ecommerce",
    desc: "Online Storefront, Payments, Product Catalog",
    industryTags: ["eCommerce"],
    example: "Online Sellers"
  },
  {
    icon: <FiPackage size={20} />,
    title: "Assets Management",
    link: "assets-management",
    desc: "Asset Tracking, Maintenance, Depreciation",
    industryTags: ["General"],
    example: "Facilities, Equipment"
  },
  {
    icon: <FiTruck size={20} />,
    title: "Procurement",
    link: "procurement",
    desc: "Vendor Management, Purchase Requests",
    industryTags: ["Manufacturing", "Retail"],
    example: "Retailers, Factories"
  },
  {
    icon: <FiUsers size={20} />,
    title: "CRM",
    link: "crm",
    desc: "Customer Relationships, Leads, Follow-ups",
    industryTags: ["eCommerce", "Services"],
    example: "Agencies, Sales Teams"
  }
];

const ModuleSection = () => {
  const { t } = useTranslation();
  const [selectedIndustry, setSelectedIndustry] = useState("eCommerce");
  const industries = ["eCommerce", "Retail", "Logistics", "Manufacturing", "Construction", "IT", "General", "Services"];

  useEffect(() => {
    const stored = localStorage.getItem("preferredIndustry");
    if (stored) setSelectedIndustry(stored);
  }, []);

  useEffect(() => {
    localStorage.setItem("preferredIndustry", selectedIndustry);
  }, [selectedIndustry]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2
        }
      }
    ]
  };

const renderModule = (mod, i) => (
    <motion.div
        key={i}
        className={`flex flex-col justify-between space-y-2 text-left border p-4 rounded-lg transition-all relative group h-full mt-6 ${
            mod.industryTags?.includes(selectedIndustry)
                ? "border-primary bg-primary/5"
                : "border-gray-200"
        }`}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: i * 0.1 }}
        viewport={{ once: true }}
    >
        <div>
            <div className="flex items-center space-x-3">
                <div className="p-2 bg-primary/10 rounded-full text-primary">
                    {mod.icon}
                </div>
                <h3 className="font-bold text-gray-800">
                    <Link to={`/modules/${mod.link}`} className="hover:text-primary">
                        {mod.title}
                    </Link>
                </h3>
            </div>
            <p className="text-sm text-gray-600 pl-10">{mod.desc}</p>
        </div>

        {/* Bottom right example tag */}
        <div className="absolute bottom-2 right-2 text-gray-500  text-[11px] text-xs bg-gray-200 text-gray-700 px-2 py-1 rounded-full">
            {mod.example}
        </div>
    </motion.div>
);

  return (
    <section className="bg-gray-50 py-20 px-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
          <div>
            <motion.h2
              className="text-3xl font-bold text-gray-900 mb-2 text-left"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <span className="text-primary">RedERP</span> {t("modulesTitle")}
            </motion.h2>
            <motion.p
              className="text-gray-600 text-left"
              initial={{ opacity: 0, y: -10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              {t("modulesSubtitle")}
            </motion.p>
          </div>

          <motion.div
            className="text-left md:text-right w-full md:w-auto"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            <label className="mr-2 font-medium">Filter by Industry:</label>
            <select
              className="border border-gray-300 px-4 py-2 rounded focus:outline-none"
              value={selectedIndustry}
              onChange={(e) => setSelectedIndustry(e.target.value)}
            >
              {industries.map((ind, idx) => (
                <option key={idx} value={ind}>{ind}</option>
              ))}
            </select>
          </motion.div>
        </div>

        {/* Desktop Grid */}
        <div className="hidden md:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
          {modules.map(renderModule)}
        </div>

        {/* Mobile Carousel */}
        <div className="md:hidden">
          <Slider {...sliderSettings}>
            {modules.map(renderModule)}
          </Slider>
        </div>

        {/* CTA */}
        <div className="mt-16 bg-primary text-white rounded-xl px-6 max-w-7xl mx-auto shadow relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-black/20 to-transparent z-0" />

            <div className="flex flex-col md:flex-row items-center justify-between gap-8 relative z-10">
                
                {/* Text Section */}
                                <div className="text-center md:text-left flex-1 py-12">
                                <h3 className="text-2xl font-bold mb-2">
                                    Customized for more than 50 industries, for 5$/month for all users.
                                </h3>
                                <p className="text-sm mb-4">
                                    Further customization available per request. Discover how RedERP can help your business grow.
                                </p>
                                
                                <div className="mt-6 flex flex-wrap justify-center md:justify-start gap-4 text-white text-sm">
                  <div className="flex items-center space-x-2">
                    <FiTruck className="text-white" />
                    <span>Logistics</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FiSettings className="text-white" />
                    <span>Manufacturing</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FiShoppingBag className="text-white" />
                    <span>Retail</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FiUsers className="text-white" />
                    <span>Services</span>
                  </div>

                  <div className="flex items-center space-x-2">
                    <FiChevronsRight className="text-white" />
                    <span>And more...</span>
                  </div>
                </div>

                <div className="py-4 mt-4 flex flex-wrap justify-center md:justify-start gap-4 text-white text-sm">
                                <a
                                    href="https://app.rederp.pro/sign-up"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
                                >
                                    Get Started for Free
                                </a>
                                </div>
                                </div>

                                {/* Decorative Image */}
                <div className="hidden md:block flex-1 h-full absolute right-0 top-0">
                <img
    src="/assets/images/ctaindustry.png"
    alt="Industries Illustration"
    className="absolute bottom-0 right-0 max-w-none w-[300px] lg:w-[300px] xl:w-[300px] object-contain animate-float"
    />
                </div>
            </div>


        </div>


      </div>
    </section>
  );
};

export default ModuleSection;

import Layout from "../components/Layout";
import { Helmet } from "react-helmet-async";

const Learning = () => {
  return (
    <Layout>
      <Helmet>
        <title>Learning | RedERP</title>
        <meta name="description" content="Educational resources and guides for using RedERP." />
      </Helmet>
      <section className="py-32 px-6 text-center">
        <h1 className="text-4xl font-bold text-primary">Learning Center</h1>
        <p className="mt-4 text-lg text-gray-600">Explore documentation, videos, and tutorials for RedERP.</p>
      </section>
    </Layout>
  );
};

export default Learning;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FiCheckCircle } from "react-icons/fi";

const Hero = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <section className="px-6 bg-gradient-to-br from-[rgb(84,13,14)] to-black relative overflow-hidden">
      <motion.div
        className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 items-center relative z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Left Column: Text */}
        <div className="text-center md:text-left pt-16 pb-24">
          <h1 className="text-4xl font-extrabold text-white mb-6 leading-tight">
            {t("heroTitle")}
          </h1>
          <p className="text-lg text-white mb-8">
            {t("heroSubtitle")}
          </p>

          <div className="grid grid-cols-2 gap-4 text-white mb-8">
            <div className="flex items-center space-x-2">
              <FiCheckCircle className="text-green-500" />
              <span className="font-semibold text-sm">{t("trial")}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiCheckCircle className="text-green-500" />
              <span className="font-semibold text-sm">{t("noCreditCard")}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiCheckCircle className="text-green-500" />
              <span className="font-semibold text-sm">{t("noUpfrontCost")}</span>
            </div>
            <div className="flex items-center space-x-2">
              <FiCheckCircle className="text-green-500" />
              <span className="font-semibold text-sm">{t("unlimitedUsers")}</span>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-center md:justify-start items-center gap-4 pt-10">
          <a
  href="https://app.rederp.pro/sign-up"
  target="_blank"
  rel="noopener noreferrer"
  className="w-full sm:w-auto bg-primary text-white px-6 py-3 rounded-full font-medium hover:bg-primary/90 text-center"
>
  {t("signUp")}
</a>
<button
  onClick={openModal}
  className="w-full sm:w-auto text-white border border-white px-6 py-3 rounded-full font-medium hover:bg-white hover:text-primary text-center"
>
  {t("demo")}
</button>
          </div>
        </div>

        {/* Right Column: Image */}
        <motion.div
  className="hidden md:flex w-full justify-center md:justify-end items-end pt-16"
  initial={{ x: 100, opacity: 0 }}
  animate={{ x: 0, opacity: 1 }}
  transition={{ duration: 1, delay: 0.5 }}
>
  <img
    src="/assets/images/hero5.png"
    alt="RedERP Hero"
    className="w-[100%] h-auto object-contain mb-[-1px]"
  />
</motion.div>
      </motion.div>

      {/* Modal for YouTube video */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg overflow-hidden w-11/12 sm:w-3/4 lg:w-1/2">
            <div className="flex justify-end p-2">
              <button onClick={closeModal} className="text-gray-600 text-2xl hover:text-black">×</button>
            </div>
            <div className="relative pt-[56.25%]">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/SXFwLFZ6Gmg?autoplay=1"
                title="RedERP Demo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;

import { motion } from "framer-motion";
import CountUp from "react-countup";
import { FiGlobe, FiUserCheck, FiTrendingUp, FiActivity } from "react-icons/fi";

const stats = [
  {
    icon: <FiGlobe size={28} />,
    label: "Countries Served",
    value: 12,
    suffix: "+"
  },
  {
    icon: <FiUserCheck size={28} />,
    label: "Active Users",
    value: 3000,
    suffix: "+"
  },
  {
    icon: <FiTrendingUp size={28} />,
    label: "Invoices Processed Monthly",
    value: 15000,
    suffix: "+"
  },
  {
    icon: <FiActivity size={28} />,
    label: "Customer Satisfaction",
    value: 98,
    suffix: "%"
  }
];

const GlobalReachSection = () => {
  return (
    <section
      className="relative py-24 px-6 bg-white border-t overflow-hidden"
      style={{ backgroundImage: `url("/assets/images/worldmap-light.png")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}
    >
      <div className="absolute inset-0 bg-white bg-opacity-85 z-0"></div>

      <div className="max-w-6xl mx-auto text-center relative z-10">
        <motion.h2
          className="text-3xl font-bold text-gray-900 mb-4"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Trusted by Businesses Around the World
        </motion.h2>
        <motion.p
          className="text-gray-600 max-w-xl mx-auto mb-12"
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          RedERP powers growing companies in multiple sectors, helping them operate smarter and scale confidently.
        </motion.p>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8">
          {stats.map((stat, i) => (
            <motion.div
              key={i}
              className="flex flex-col items-center justify-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: i * 0.1 }}
              viewport={{ once: true }}
            >
              <div className="text-primary mb-2">{stat.icon}</div>
              <div className="text-3xl font-bold text-gray-800">
                <CountUp end={stat.value} duration={2} separator="," />{stat.suffix}
              </div>
              <div className="text-sm text-gray-600">{stat.label}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GlobalReachSection;

import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import blogPosts from "../data/blogPosts.json";

const BlogTeaserSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-white py-20 px-6 border-t">
      <div className="max-w-6xl mx-auto">
        {/* Header + Newsletter */}
        <div className="text-center mb-12">
          <motion.h2
            className="text-3xl font-bold text-gray-900 mb-2"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Learn how to grow your business with RedERP insights
          </motion.h2>
          <motion.p
            className="text-gray-600"
            initial={{ opacity: 0, y: -10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Get productivity tips, use cases, and product updates delivered to your inbox.
          </motion.p>

          <form className="mt-6 flex flex-col sm:flex-row justify-center gap-3">
            <input
              type="email"
              placeholder="Enter your email"
              className="px-4 py-3 w-full sm:w-72 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            />
            <button
              type="submit"
              className="bg-primary text-white px-6 py-3 rounded-md hover:bg-primary/90"
            >
              Subscribe
            </button>
          </form>
        </div>

        {/* Blog Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
          {blogPosts.map((post, i) => (
            <motion.div
              key={i}
              className="bg-gray-50 rounded-lg shadow-sm hover:shadow-md overflow-hidden transition"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: i * 0.2 }}
              viewport={{ once: true }}
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-5">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">{post.title}</h3>
                <p className="text-sm text-gray-600 mb-4">{post.summary}</p>
                <a
                  href={post.link}
                  className="inline-flex items-center text-primary font-medium hover:underline"
                >
                  Read More <FiArrowRight className="ml-2" />
                </a>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogTeaserSection;

import Layout from "../../components/Layout";
import { Helmet } from "react-helmet-async";
import {
  FiShoppingCart,
  FiCreditCard,
  FiBox,
  FiGlobe,
  FiBarChart2,
  FiUsers,
  FiImage,
  FiTruck,
  FiSettings,
} from "react-icons/fi";

const ModuleECommerce = () => {
  const trackCTA = () => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "click", {
        event_category: "CTA",
        event_label: "Sign Up - eCommerce Page",
      });
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>eCommerce | RedERP</title>
        <meta
          name="description"
          content="RedERP eCommerce module: Build and manage your online store, inventory, payments, shipping, and analytics — all from one unified system."
        />
      </Helmet>

      {/* Hero Block with image */}
      <section className="bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between gap-10 relative">
          {/* Text Section */}
          <div className="flex-1 text-left mt-16">
            <h1 className="text-4xl font-bold mb-4">eCommerce</h1>
            <p className="mb-6 text-sm sm:text-base">
              Launch, manage, and grow your online store with RedERP. From product catalog to payments and shipping, control everything from one place.
            </p>
            <a
              href="https://app.rederp.pro/sign-up"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackCTA}
              className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
            >
              Get Started Now
            </a>
          </div>

          {/* Decorative Image */}
          <div className="flex-1 hidden md:flex justify-end mt-12 mb-0">
            <img
              src="/assets/images/ecommerce1.png"
              alt="eCommerce Illustration"
              className="w-[420px] lg:w-[420px] xl:w-[420px] h-[310px] object-contain"
            />
          </div>
        </div>
      </section>

      {/* eCommerce Features */}
      <section className="py-20 px-6 bg-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-left">
          {[
            {
              icon: <FiShoppingCart size={24} />,
              title: "Online Storefront",
              desc: "Your own branded shop to sell products or services with ease.",
            },
            {
              icon: <FiBox size={24} />,
              title: "Product Management",
              desc: "Manage product variations, stock levels, pricing, and categories.",
            },
            {
              icon: <FiCreditCard size={24} />,
              title: "Integrated Payments",
              desc: "Accept payments via cards, PayPal, Stripe, or bank transfer.",
            },
            {
              icon: <FiTruck size={24} />,
              title: "Shipping Options",
              desc: "Offer flat-rate, local pickup, or real-time carrier integration.",
            },
            {
              icon: <FiGlobe size={24} />,
              title: "Multi-Currency & Tax",
              desc: "Sell internationally with localized tax and currency settings.",
            },
            {
              icon: <FiBarChart2 size={24} />,
              title: "Sales Analytics",
              desc: "Monitor orders, revenue, and performance in real time.",
            },
            {
              icon: <FiImage size={24} />,
              title: "Custom Store Design",
              desc: "Match your brand with customizable themes and product galleries.",
            },
            {
              icon: <FiUsers size={24} />,
              title: "Customer Accounts",
              desc: "Enable customer registration, order history, and saved preferences.",
            },
            {
              icon: <FiSettings size={24} />,
              title: "Coupon & Discounts",
              desc: "Create limited-time promotions and discount codes easily.",
            },
          ].map((item, i) => (
            <div
              key={i}
              className="p-6 bg-gray-50 border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all"
            >
              <div className="flex items-center gap-3 text-primary mb-3">
                {item.icon}
                <h3 className="font-bold">{item.title}</h3>
              </div>
              <p className="text-sm text-gray-600">{item.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20 bg-gradient-to-br from-[rgb(84,13,14)] to-black text-white text-center">
        <div className="max-w-xl mx-auto px-6">
          <h3 className="text-3xl font-bold mb-4">Launch Your Online Store with RedERP</h3>
          <p className="mb-6">Start your 7-day free trial. No credit card required.</p>
          <a
            href="https://app.rederp.pro/sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-primary font-semibold px-6 py-3 rounded-full hover:bg-gray-100"
          >
            Try eCommerce Module
          </a>
        </div>
      </section>
    </Layout>
  );
};

export default ModuleECommerce;

import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Topbar = () => {
  const { t, i18n } = useTranslation();
  const [langOpen, setLangOpen] = useState(false);
  const langRef = useRef(null);

  const languages = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
    { code: "ar", label: "العربية" },
    { code: "es", label: "Español" },
    { code: "ko", label: "한국어" },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (langRef.current && !langRef.current.contains(event.target)) {
        setLangOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div
      className="bg-gray-100 text-sm py-2 px-6 flex justify-end space-x-4 items-center relative z-20 border-b"
      style={{ borderColor: "#BD1522" }}
    > 
      <a href="https://app.rederp.pro/sign-up" className="hover:text-primary font-medium">
        {t("signUp")}
      </a>
      <a
        href="https://app.rederp.pro/sign-in"
        className="bg-primary text-white px-4 py-1 rounded hover:bg-primary/90 text-sm"
      >
        {t("signIn")}
      </a>
      <span className="text-gray-300">|</span>
      <div className="relative" ref={langRef}>
        <button onClick={() => setLangOpen((prev) => !prev)} className="hover:text-primary">
          🌐 {languages.find((l) => l.code === i18n.language)?.code.toUpperCase() || "EN"}
        </button>
        {langOpen && (
          <div className="absolute bg-white shadow rounded mt-2 text-base p-4 space-y-2 z-50 right-0 w-64">
            {languages.map((lang) => (
              <button
                key={lang.code}
                onClick={() => changeLanguage(lang.code)}
                className="hover:text-primary hover:bg-gray-100 px-3 py-2 w-full text-left"
              >
                <span className="font-semibold">{lang.code.toUpperCase()}</span> – {lang.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Topbar;

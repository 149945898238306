import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FaQuoteLeft, FaStar } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    name: "Sarah Kareem",
    role: "Operations Manager, Cafe Delight",
    quoteKey: "testimonial1",
    avatar: "/assets/images/sarah.png",
    rating: 5
  },
  {
    name: "Mohamed Al-Nour",
    role: "CEO, SmartLogix",
    quoteKey: "testimonial2",
    avatar: "/assets/images/mohamed.png",
    rating: 5
  },
  {
    name: "Emily Ruiz",
    role: "Accountant, BuildNow Co.",
    quoteKey: "testimonial3",
    avatar: "/assets/images/emily.png",
    rating: 4
  }
];

const TestimonialCard = ({ t, testimonial, i }) => (
  <motion.div
    key={i}
    className="bg-white rounded-xl shadow-lg p-6 text-left hover:shadow-xl transition-all duration-300 relative flex flex-col h-full"
    initial={{ opacity: 0, y: 30 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: i * 0.15 }}
    viewport={{ once: true }}
  >
    <div className="flex items-center mb-4 gap-3">
      <img
        src={testimonial.avatar}
        alt={testimonial.name}
        className="w-12 h-12 rounded-full object-cover border border-gray-200"
      />
      <div>
        <h4 className="font-semibold text-gray-900 text-sm">{testimonial.name}</h4>
        <span className="text-xs text-gray-500">{testimonial.role}</span>
      </div>
    </div>

    <FaQuoteLeft className="text-primary mb-3" size={20} />
    <p className="text-gray-700 text-base leading-relaxed italic mb-4 flex-1">
      {t(testimonial.quoteKey)}
    </p>

    {/* Rating */}
    <div className="flex space-x-1 mt-auto">
      {Array.from({ length: testimonial.rating }).map((_, idx) => (
        <FaStar key={idx} className="text-yellow-400" size={14} />
      ))}
    </div>
  </motion.div>
);

const TestimonialSection = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2
        }
      }
    ]
  };

  return (
    <section className="bg-gray-100 py-20 px-6">
      <div className="max-w-6xl mx-auto text-center">
        <motion.h2
          className="text-3xl font-bold text-gray-900 mb-12"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          {t("testimonialsTitle")}
        </motion.h2>

        {/* Desktop View */}
        <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, i) => (
            <TestimonialCard key={i} t={t} testimonial={testimonial} i={i} />
          ))}
        </div>

        {/* Mobile Carousel View */}
        <div className="md:hidden">
          <Slider {...settings}>
            {testimonials.map((testimonial, i) => (
              <div key={i} className="pr-4">
                <TestimonialCard t={t} testimonial={testimonial} i={i} />
              </div>
            ))}
          </Slider>
        </div>


        <div className="mt-16 text-center">
  <h3 className="text-xl font-semibold text-gray-800 mb-1">
  Browse our use cases and see how businesses run smarter, faster, and leaner.
  </h3>
  <a
    href="/use-cases"
    className="inline-block border border-gray-400 text-gray-800 px-6 mt-8 py-3 rounded-full font-medium hover:bg-gray-100 transition"
  >Discover Real-World Success with RedERP
     
  </a>
</div>




      </div>
      
    </section>
  );
};

export default TestimonialSection;

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";

const UseCaseHighlight = () => {
  return (
    <section className="relative bg-gray-50 py-24 px-6 overflow-hidden  border-t">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16 items-center relative z-10">

        {/* Left Image with red overlay */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="relative"
        >
          <div className="absolute inset-0 bg-primary/50 rounded-xl z-10 mix-blend-multiply"></div>
          <img
            src="/assets/images/pool.png"
            alt="Pool Construction ERP Use Case"
            className="w-full h-auto rounded-xl shadow-lg relative z-0 object-cover"
          />
        </motion.div>

        {/* Right Content */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          viewport={{ once: true }}
        >
          <h2 className="text-3xl md:text-2xl font-extrabold text-gray-900 leading-snug mb-6">
            A Dive into Efficiency
            <span className="block text-primary mt-1">RedERP for Pool Construction Companies</span>
          </h2>

          <p className="text-gray-600 text-base md:text-md mb-6">
            RedERP empowers pool construction teams with a seamless workflow — from initial design requests to final delivery. Forget spreadsheets and scattered systems.
          </p>

          <ul className="space-y-3 mb-8 text-sm md:text-base text-gray-700">
            <li>✓ CRM integration and design request tracking</li>
            <li>✓ On-site task and progress monitoring</li>
            <li>✓ Automated procurement and inventory sync</li>
            <li>✓ Field team time tracking & invoice generation</li>
          </ul>

          <Link
            to="/use-cases"
            className="inline-block bg-primary text-white px-6 py-3 rounded-full font-semibold hover:bg-primary/90 transition"
          >
            Explore All Use Cases
          </Link>

          {/* Testimonial Block */}
          <div className="mt-10 bg-gray-50 p-6 rounded-lg shadow border border-gray-100">
            <div className="flex items-start gap-3">
              <FaQuoteLeft className="text-primary mt-1" />
              <div>
                <p className="text-sm text-gray-700 italic mb-2">
                  “Thanks to RedERP, our construction planning is now automated, and team coordination has never been better. It changed how we run our operations.”
                </p>
                <h4 className="font-bold text-gray-900 text-sm">Amor </h4>
                <span className="text-xs text-gray-500">Director, Tri-Tech, Tunisia</span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Optional background visual */}
      <div className="absolute -bottom-20 -right-20 w-[400px] h-[400px] bg-primary/10 rounded-full blur-3xl z-0 pointer-events-none"></div>
    </section>
  );
};

export default UseCaseHighlight;

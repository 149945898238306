import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  FiFacebook,
  FiTwitter,
  FiLinkedin,
  FiMail,
  FiInstagram
} from "react-icons/fi";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-100 text-gray-700 text-sm border-t">
      <div className="max-w-7xl mx-auto px-6 py-14 grid grid-cols-1 md:grid-cols-4 gap-10">
        {/* Branding */}
        <div>
          <img
            src="/assets/images/logo.png"
            alt="RedERP Logo"
            className="h-8 mb-4"
          />
          <p className="text-gray-600 text-sm leading-relaxed">
            {t("footerDescription") || "Powering smarter businesses with intuitive ERP, CRM, and eCommerce solutions. Built for growth, trusted by industries."}
          </p>
        </div>

        {/* Products */}
        <div>
          <h4 className="font-semibold mb-3 text-gray-900">Product</h4>
          <ul className="space-y-2">
            <li><Link to="/modules/sales-pos" className="hover:text-primary">Sales & PoS</Link></li>
            <li><Link to="/modules/inventory-stock" className="hover:text-primary">Inventory</Link></li>
            <li><Link to="/modules/finance" className="hover:text-primary">Finance</Link></li>
            <li><Link to="/pricing" className="hover:text-primary">Pricing</Link></li>
          </ul>
        </div>

        {/* Resources */}
        <div>
          <h4 className="font-semibold mb-3 text-gray-900">Resources</h4>
          <ul className="space-y-2">
            <li><Link to="/learning" className="hover:text-primary">Learning Center</Link></li>
            <li><Link to="/use-cases" className="hover:text-primary">Use Cases</Link></li>
            <li><Link to="/blog" className="hover:text-primary">Blog</Link></li>
            <li><Link to="/faq" className="hover:text-primary">FAQs</Link></li>
          </ul>
        </div>

        {/* Company */}
        <div>
          <h4 className="font-semibold mb-3 text-gray-900">Company</h4>
          <ul className="space-y-2">
            <li><Link to="/about" className="hover:text-primary">About Us</Link></li>
            <li><Link to="/contact" className="hover:text-primary">Contact</Link></li>
            <li><a href="https://app.rederp.pro/sign-up" className="hover:text-primary" target="_blank" rel="noopener noreferrer">Start Free Trial</a></li>
            <li><Link to="/legal/privacy" className="hover:text-primary">Privacy Policy</Link></li>
          </ul>
        </div>
      </div>

      <div className="border-t border-gray-200 text-center py-6 px-4 text-xs text-gray-500">
        <div className="flex flex-col md:flex-row justify-between items-center max-w-7xl mx-auto">
          <p className="mb-3 md:mb-0">&copy; {new Date().getFullYear()} RedERP. {t("footer") || "All rights reserved."}. Powered by Red Digital Factory L.L.C Qatar. </p>
          <div className="flex gap-4 text-gray-500">
            <a href="#" className="hover:text-primary"><FiInstagram /></a>
            <a href="#" className="hover:text-primary"><FiTwitter /></a>
            <a href="#" className="hover:text-primary"><FiLinkedin /></a>
            <a href="mailto:info@rederp.pro" className="hover:text-primary"><FiMail /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

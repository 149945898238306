import { motion } from "framer-motion";
import { FiShield, FiCloud, FiLock, FiCheckCircle } from "react-icons/fi";

const compliancePoints = [
  {
    icon: <FiShield size={24} />,
    title: "ISO Certified",
    desc: "We follow ISO/IEC 27001 best practices to manage information security."
  },
  {
    icon: <FiLock size={24} />,
    title: "GDPR Compliant",
    desc: "We uphold the highest standards in data privacy and protection."
  },
  {
    icon: <FiCloud size={24} />,
    title: "Azure Cloud Infrastructure",
    desc: "RedERP is hosted on secure Microsoft Azure servers with high availability and disaster recovery."
  },
  {
    icon: <FiCheckCircle size={24} />,
    title: "End-to-End Encryption",
    desc: "Your data is encrypted in transit and at rest using industry-grade protocols."
  }
];

const SecuritySection = () => {
  return (
    <section className="bg-gray-100 py-20 px-6 border-t">
      <div className="max-w-6xl mx-auto">
        <motion.h2
          className="text-3xl font-bold text-gray-900 text-center mb-4"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Security & Compliance
        </motion.h2>
        <motion.p
          className="text-center text-gray-600 mb-12 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: -10 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          We take security seriously. RedERP is built on a secure, scalable infrastructure to keep your data protected.
        </motion.p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-10">
          {compliancePoints.map((point, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow hover:shadow-md transition"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <div className="flex items-center space-x-4 mb-3 text-primary">
                {point.icon}
                <h3 className="text-lg font-bold text-gray-800">{point.title}</h3>
              </div>
              <p className="text-sm text-gray-600">{point.desc}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SecuritySection;

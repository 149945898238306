import Layout from "../../components/Layout";
import { Helmet } from "react-helmet-async";

const IndustryPage = ({ industryName }) => {
  return (
    <Layout>
      <Helmet>
        <title>{industryName} | RedERP</title>
        <meta name="description" content={`Discover how RedERP empowers the ${industryName} industry.`} />
      </Helmet>
      <section className="py-32 px-6 text-center">
        <h1 className="text-4xl font-bold text-primary">{industryName}</h1>
        <p className="mt-4 text-lg text-gray-600">
          Tailored solutions and workflows for the {industryName} industry.
        </p>
      </section>
    </Layout>
  );
};

export default IndustryPage;

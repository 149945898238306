import { useTranslation } from "react-i18next";
import Topbar from "./Topbar";
import Navbar from "./Navbar";
import Footer from "./Footer";

const modules = [
  "Sales & Point of Sale", "Inventory & Stock", "Finance", "CRM",
  "Human Resources", "Procurement", "eCommerce", "Logistics",
  "Project Management", "Manufacturing & Production", "BI & Reporting", "Asset Management"
];

const industries = [
  "Retail", "Manufacturing", "Healthcare", "Construction", "Education", "Logistics"
];

const Layout = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <main className="text-gray-800 font-sans" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <Topbar />
      <Navbar modules={modules} industries={industries} />
      {children}
      <Footer />
    </main>
  );
};

export default Layout;

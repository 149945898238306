// App.jsx
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import Home from "./pages/Home";
import UseCases from "./pages/UseCases";
import Demo from "./pages/Demo";
import Pricing from "./pages/Pricing";
import Learning from "./pages/Learning";
import About from "./pages/About";
import ModulePointOfSale from "./pages/modules/ModulePointOfSale";
import ModuleSales from "./pages/modules/ModuleSales";
import ModuleECommerce from "./pages/modules/ModuleECommerce";
import ModuleInventoryStock from "./pages/modules/ModuleInventoryStock";


import IndustryPage from "./pages/industries/[IndustryName]";
import NotFound from "./pages/NotFound";

function App() {
  const { i18n } = useTranslation();



  const industries = [
    "Retail", "Manufacturing", "Healthcare", "Construction", "Education", "Logistics"
  ];

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/use-cases" element={<UseCases />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/learning" element={<Learning />} />
          <Route path="/about" element={<About />} />
          <Route path="/modules/pos" element={<ModulePointOfSale />} />
          <Route path="/modules/sales" element={<ModuleSales />} />
          <Route path="/modules/ecommerce" element={<ModuleECommerce />} />
          <Route path="/modules/inventory-stock" element={<ModuleInventoryStock />} />


          {industries.map((industry, i) => (
            <Route
              key={`industry-${i}`}
              path={`/industries/${industry.toLowerCase().replace(/\s+/g, '-')}`}
              element={<IndustryPage industryName={industry} />}
            />
          ))}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;